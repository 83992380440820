import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import FaqSection from "../../sections/Faq/FaqSection";
import FaqList from "../../sections/Faq/FaqList";
import Footer from "../../components/Footer";

const PageContent = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <FaqSection />
      <FaqList />
      <Footer />
    </>
  );
};

const Faq = () => {
  return (
    <>
      <Header />
      <PageLayout title="FAQ">
        <PageContent />
      </PageLayout>
    </>
  );
};

export default Faq;
