import * as React from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";

import * as styles from "./index.module.scss";
import Svg from "../../../../../images/combined-shape.inline.svg";
import ReverseSvg from "../../../../../images/reverse-combined-shape.inline.svg";

const FaqListItem = ({ title, description }) => {
  const [height, setHeight] = React.useState(0);

  const onToggleIsOpen = React.useCallback(
    () => setHeight(height === 0 ? "auto" : 0),
    [setHeight, height]
  );

  return (
    <div>
      <button
        className={styles.titleWrapper}
        aria-expanded={height !== 0}
        aria-controls="example-panel"
        onClick={onToggleIsOpen}
      >
        <span className={styles.title}>{title}</span>
        {height === "auto" ?
            <ReverseSvg className={styles.arrow}/>
            : <Svg className={styles.arrow}/>}
      </button>
      <AnimateHeight id="example-panel" duration={500} height={height}>
        <span className={styles.description}>{description}</span>
      </AnimateHeight>
      <div className={styles.divider} />
    </div>
  );
};

FaqListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FaqListItem;
