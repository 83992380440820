import * as React from "react";

import * as styles from "./index.module.scss";
import FAQ_LIST from "../../../constants/Faq";

import FaqListItem from "./components/FaqListItem";

const FaqList = () => {
  return (
    <div className={styles.faqListWrapper}>
      {FAQ_LIST.map((faqItem, index) => (
        <FaqListItem
          title={faqItem.title}
          description={faqItem.description}
          key={index}
        />
      ))}
    </div>
  );
};

export default FaqList;
