import * as React from "react";
import * as styles from "./index.module.scss";
import Svg from "../../../images/faq-banner.inline.svg";
import faq from "../../../images/faq-1.png";
import SectionLayout from "../../../components/SectionLayout";
import BackgroundImage from "../../../components/BackgroundImage";

const FaqSection = () => {
  return (
    <SectionLayout className={styles.sectionWrapper}>
      <BackgroundImage
        backgroundImageDesktop={faq}
        backgroundImageTablet={faq}
        backgroundImageMobile={faq}
        imageStyle={styles.backgroundImage}
        Svg={Svg}
      >
        <div className={styles.backgroundImageChildren}>
          <span className={styles.title}>FAQ</span>
        </div>
      </BackgroundImage>
    </SectionLayout>
  );
};

export default FaqSection;
